
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';

import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import ReasonService from '../../services/reason.service';
import CompaniesService from '../../../Companies/services/companies.service';
import { Reason } from '../../entities/reason.entity';
import { Company } from '../../../Companies/entities/company.entity';
import { Order } from '../../../Orders/entities/order.entity';
import OrderService from '../../../Orders/services/order.service';

@Component({ components: { AlertError, DeleteDialog } })
export default class ReasonCreateComponent extends Vue {
  public $refs: any;

  private reason: Reason = new Reason();

  private companies: Company[] = [];

  private statuses: any[] = [];

  private showDialogDelete: boolean = false;

  private errors: any[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private async getReasonById() {
    const { id } = this.$route.params;
    const reason = await ReasonService.getReasonById(id);
    this.reason = new Reason(reason);
    this.getOrderStatus();
    this.$loader.close();
  }

  private async setOrderStatus(orderStatus: string) {
    this.reason.statuses = [orderStatus];
  }

  private async getOrderStatus() {
    const statuses: Order[] = await OrderService.getOrderFinalStatuses(this.reason.companyId);
    this.statuses = statuses.map(orderStatus => orderStatus);
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem(reason: Reason) {
    this.reason
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Motivo excluído com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.reason
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Motivo cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'reason-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar reason',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.reason
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Motivo alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'reason-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'reason-list',
    });
  }

  private async setCompanyId(companyId: string) {
    this.reason.companyId = companyId;
    this.getOrderStatus();
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private created() {
    this.getCompanies();
    if (this.isEdit) {
      this.getReasonById();
    }
    this.getOrderStatus();
  }
}
